import { defineStore } from "pinia"

export const useAppStore = defineStore("app", {
  state: () => {
    return {
      breadcrumbs: [],
      appTitle: 'Padelista',
      urlParams: {},
    }
  },
  getters: {
    getBreadcrumbs() {
      return this.breadcrumbs
    },
    getUpdateUrlParams() {
      return this.urlParams
    },
    getAppTitle() {
      return this.appTitle
    },
  },
  actions: {
    setBreadcrumbs(breadcrumbs) {
      this.breadcrumbs = breadcrumbs
    },
    setAppTitle(show) {
      this.appTitle = show
    },
    setUpdateUrlParams(params) {
      this.urlParams = params
    },
  },
})
