import { lsGet } from "@/helpers/localStorageHelper"
import navItems from '@/navigation/vertical'
import i18n from "@/plugins/i18n"
import { useAppStore } from "@/stores/app"
import { useRoleStore } from "@/stores/role"
import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import routes from '~pages'

const { t } = i18n.global

const userData = ref({})
const appStore = ref()
const roleStore = ref()

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    ...setupLayouts(routes),
  ],
})

const isPathValid = (to, navItem) => {
  let toPath = to.split('/')[1]
  let navPath = navItem.to?.name
  return navItem.to && (toPath==navPath || toPath+'s' == navPath) && roleStore.value.checkNavPerm(navItem)
}

const checkPathIfExits = (path, nav_item) => {
  let valid_nav_item = false

  if( Array.isArray(nav_item) ) {
    let validRoute = nav_item.filter(navItem => {
      return checkPathIfExits(path, navItem.children ? navItem.children : navItem)
    })
    
    valid_nav_item = validRoute.length ? validRoute[validRoute.length - 1] : false
  } else if( isPathValid(path, nav_item) ) {
    valid_nav_item = nav_item
  }

  if( valid_nav_item ) {
    let toPath = path.split('/')
    let backPath = '/'+valid_nav_item.to?.name || '/'
    if (router.options.history.state.back) {
      backPath = router.options.history.state.back
    }

    let breadcrumbs = [
      { title: t('dashboard'), disabled: false, href: '/' },
      { title: t(valid_nav_item.title), disabled: toPath.length<3, href: backPath },
    ]

    if( toPath.length>2 ) {
      breadcrumbs.push({
        title: t(toPath[1]) + ' #'+(toPath[2] || '0'),
        disabled: true,
      })
    }

    appStore.value.setBreadcrumbs(breadcrumbs)
  }

  return valid_nav_item ? valid_nav_item.title : false
}

router.beforeEach((to, from, next) => {
  
  userData.value = lsGet('userData', true) || null
  roleStore.value = useRoleStore()
  appStore.value = useAppStore()
  
  let title = (to.path=='/404' ? '404' : (to.path=='/login' ? 'login' : 'dashboard'))
  if( to.path!='/' && ['/login', '/forgot-password', '/reset-password', '/404'].indexOf(to.path)==-1 ) {
    title = checkPathIfExits(to.path, navItems)
    if( !title ) {
      next('/404')
    }
  }
  if( to.path == '/' ) {
    appStore.value.setBreadcrumbs([{ title: t('dashboard'), href: '/' }])
  }
  
  document.title = t(title) + " - " + import.meta.env.VITE_COMPANY_NAME

  next()
  
  if (to?.path === from?.path && to?.fullPath !== from?.fullPath) {
    appStore.value.setUpdateUrlParams(to.query)
  }

})

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
export default router
