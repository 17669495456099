<script setup>
import i18n from "@/plugins/i18n"

const props = defineProps({
  today: {
    type: Number,
    default: 0,
  },
  active: {
    type: Number,
    default: 0,
  },
  canceled: {
    type: Number,
    default: 0,
  },
  all: {
    type: Number,
    default: 0,
  },
})

const { t } = i18n.global
</script>

<template>
  <VCard>
    <VCardText>
      <h6 class="text-h6 text-primary text-no-wrap">
        {{ t('bookingOverview') }}
      </h6>
    </VCardText>

    <VCardText class="mt-2">
      <VRow>
        <VCol col="9" class="text-no-wrap">
          {{ t('today') }}
        </VCol>
        <VCol
          col="3"
          class="text-right"
        >
          {{ props.today }}
        </VCol>
      </VRow>
      <VRow>
        <VCol col="9" class="text-no-wrap">
          {{ t('active') }}
        </VCol>
        <VCol
          col="3"
          class="text-right"
        >
          {{ props.active }}
        </VCol>
      </VRow>
      <VRow>
        <VCol col="9" class="text-no-wrap">
          {{ t('canceled') }}
        </VCol>
        <VCol
          col="3"
          class="text-right"
        >
          {{ props.canceled }}
        </VCol>
      </VRow>
      <VRow>
        <VCol col="9" class="text-no-wrap">
          {{ t('all') }}
        </VCol>
        <VCol
          col="3"
          class="text-right"
        >
          {{ props.all }}
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>
