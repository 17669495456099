<script setup>
import WebsiteAnalytics from '@/components/dashboard/WebsiteAnalytics.vue'
import DateRangeSelector from "@/components/selectors/DateRangeSelector.vue"
import { useDashboardStore } from "@/stores/dashboard"
import { useRoleStore } from "@/stores/role"
import { useSnackBarStore } from "@/stores/snackbar"
import RangeDateType from '@/constants/RangeDateType'
import { reactive } from "vue"
import UserOverview from '@/components/dashboard/UserOverview.vue'
import BookingOverview from '@/components/dashboard/BookingOverview.vue'
import CourtOverview from '@/components/dashboard/CourtOverview.vue'
import ProductOverview from '@/components/dashboard/ProductOverview.vue'
import BookingGrowth from '@/components/dashboard/BookingGrowth.vue'

const roleStore = useRoleStore()
const dashboardStore = useDashboardStore()
const notification = useSnackBarStore()

const data = reactive({
  dashboard: {},
  dashboardLoading: false,
  filters: {
    date_range: RangeDateType.ALL.value,
  },
})

onMounted(async () => {
  getDashboardData()
})

const getDashboardData = () => {
  if( !roleStore.hasPerms('dashboard', 'get') ) {
    return
  }

  data.dashboardLoading = true
  dashboardStore.getDashboardData(data.filters)
    .then(res => {
      data.dashboard = res.data.data
    })
    .catch(notification.responseError)
    .finally(() => {
      data.dashboardLoading = false
    })
}

const selectDateRange = date => {
  data.filters.date_range = date
  getDashboardData()
}
</script>

<template>
  <ProgressBar v-if="data.dashboardLoading" />
  
  <div v-else>
    <VRow>
      <VCol
        cols="auto"
        class="mx-5"
      >
        <h3 class="pt-2">
          {{ $t('dashboard') }} 
          <VBtn
            icon="mdi-refresh"
            color="default"
            variant="text"
            class="ml-2"
            size="small"
            @click="getDashboardData"
          />
        </h3>
      </VCol>
      <VSpacer />
      <VCol
        cols="12" 
        sm="auto"
      >
        <DateRangeSelector
          :date-range="data.filters.date_range"
          @select-date-range="selectDateRange"
        />
      </VCol>
    </VRow>
    <VRow class="match-height">
      <VCol
        cols="12"
        md="6"
      >
        <WebsiteAnalytics 
          :total-customers="data.dashboard.total_clients"
          :total-providers="data.dashboard.total_providers"
          :total-courts="data.dashboard.total_courts"
          :total-products="data.dashboard.total_products"
          :total-booking="data.dashboard.total_booking"
          :total-issues="data.dashboard.total_issues"
        />
      </VCol>
      <VCol
        cols="12"
        md="6"
      >
        <BookingGrowth
          :total="data.dashboard.total_booking"
          :weekly-growth="data.dashboard.weekly_growth"
        />
      </VCol>
      
      <VCol
        cols="12"
        md="3"
        sm="6"
      >
        <UserOverview 
          :total-new="data.dashboard.total_clients_new"
          :total-booked="data.dashboard.total_user_booked"
          :total-issued="data.dashboard.total_clients_issue"
          :total-deleted="data.dashboard.total_deleted_users"
          />
      </VCol>
      <VCol
        cols="12"
        md="3"
        sm="6"
      >
        <BookingOverview
          :today="data.dashboard.today_booking"
          :active-soon="data.dashboard.active_booking"
          :canceled="data.dashboard.canceled_booking"
          :all="data.dashboard.total_booking"
        />
      </VCol>
      <VCol
        cols="12"
        md="3"
        sm="6"
      >
        <CourtOverview
          :total-views="data.dashboard.total_court_views"
          :has-booked="data.dashboard.total_court_booked"
          :has-issue="data.dashboard.total_court_reported_issue"
          :deleted="data.dashboard.total_court_deleted"
        />
      </VCol>
      <VCol
        cols="12"
        md="3"
        sm="6"
      >
        <ProductOverview
          :total-pending="data.dashboard.total_products_pending"
          :total-coming-soon="data.dashboard.total_products_coming_soon"
          :total-available="data.dashboard.total_products_available"
          :total-hidden="data.dashboard.total_products_hidden"
        />
      </VCol>
    </VRow>
  </div>
</template>

<style lang="scss">
@use "@core/scss/template/libs/apex-chart.scss";
</style>
