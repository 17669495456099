<script setup>
import i18n from "@/plugins/i18n"

const props = defineProps({
  totalCustomers: {
    type: Number,
    default: 0,
  },
  totalIssues: {
    type: Number,
    default: 0,
  },
  totalProviders: {
    type: Number,
    default: 0,
  },
  totalCourts: {
    type: Number,
    default: 0,
  },
  totalProducts: {
    type: Number,
    default: 0,
  },
  totalBooking: {
    type: Number,
    default: 0,
  },
})

const { t } = i18n.global

const websiteAnalytics = ref(
  {
    name: 'Traffic',
    desc: 'The traffic of the website overall',
    data: [
      {
        number: props.totalCustomers,
        text: t('customers'),
      },
      {
        number: props.totalIssues,
        text: t('issues'),
      },
      {
        number: props.totalProviders,
        text: t('providers'),
      },
      {
        number: props.totalCourts,
        text: t('courts'),
      },
      {
        number: props.totalProducts,
        text: t('products'),
      },
      {
        number: props.totalBooking,
        text: t('booking'),
      },
    ],
  })
</script>

<template>
  <VCard color="primary">
    <VCardText>
      <VRow>
        <VCol
          cols="12"
        >
          <h6 class="text-h6 text-no-wrap text-bold text-white">
            {{ websiteAnalytics.name }}
          </h6>
          <span class="text-sm text-grey">{{websiteAnalytics.desc}}</span>
        </VCol>
        <VCol
          v-for="d in websiteAnalytics.data"
          :key="d.number"
          cols="6"
          class="text-no-wrap"
        >
          <VChip
            label
            class="me-2"
          >
            {{ d.number }}
          </VChip>
          <span>{{ d.text }}</span>
        </VCol>
      </VRow>
    </VCardText>
  </VCard>
</template>

<style lang="scss">
.card-website-analytics-img {
  block-size: 160px;
}

@media screen and (min-width: 600px) {
  .card-website-analytics-img {
    position: absolute;
    margin: auto;
    inset-block-end: 40px;
    inset-block-start: -1rem;
    inset-inline-end: 1rem;
  }
}

.web-analytics-carousel {
  .v-carousel__controls {
    .v-btn:not(.v-btn--active) {
      opacity: 0.4;
    }
  }
}
</style>

