<script setup>
const props = defineProps({
  id:{
    type: Number,
    default: 0,
  },
  show:{
    type: Boolean,
    default: false,
  },
  location:{
    type: String,
    default: '',
  },
  icon:{
    type: String,
    default: '',
  },
  timeout: {
    type: Number,
    default: 10,
  },
  type: {
    type: String,
    default: '',
  },
  color: {
    type: String,
    default: '',
  },
  message: {
    type: [String, Array],
    default: '',
  },
})

const visible = ref(props.show)
</script>

<template>
  <VSnackbar
    v-model="visible"
    :timeout="props.timeout"
    :location="props.location"
    :color="props.color"
    multi-line
    v-bind="$attrs"
    transition="scroll-y-transition"
  >
    <VIcon
      :icon="props.icon"
      color="white"
    />
    <div v-if="Array.isArray(props.message)">
      <pre
        v-for="msg in props.message"
        :key="msg"
      >
        <pre>{{ msg }}</pre>
      </pre>
    </div>
    <pre v-else>
      {{ props.message }}
    </pre>
    <template #actions>
      <VBtn
        color="red"
        variant="text"
        @click="visible = false"
      >
        <VIcon
          icon="mdi-close"
          color="white"
        />
      </VBtn>
    </template>
  </VSnackbar>
</template>

