/* eslint-disable import/order */
import '@/@iconify/icons-bundle'
import App from '@/App.vue'
import '@/assets/date.scss'
import i18n from '@/plugins/i18n'
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import { loadFonts } from '@/plugins/webfontloader'
import router from '@/router'
import '@core/scss/template/index.scss'
import '@styles/styles.scss'
import '@vuepic/vue-datepicker/dist/main.css'
import "@yzfe/svgicon/lib/svgicon.css"
import { VueSvgIconPlugin } from "@yzfe/vue3-svgicon"
import mitt from "mitt"
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import "vue3-easy-data-table/dist/style.css"
import { installComponents } from "./plugins/global-components"

loadFonts()

// Create vue app
const app = createApp(App)

const emitter = mitt()

app.provide("emitter", emitter)

// Use plugins
app.use(vuetify)
app.use(createPinia())
app.use(router)
app.use(layoutsPlugin)
app.use(i18n)

// Install global components
installComponents(app)

app.use(VueSvgIconPlugin, {
  tagName: "SvgIcon",
})

// Mount vue app
app.mount('#app')
